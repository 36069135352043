// en.js
export default {
  header: {
    home: 'Home',
    download: 'Download',
    helpCenter: 'Help Center',
    signIn: 'Sign In',
    myAccount: 'My Account',
    changePwd: 'Change Password',
    signOut: 'Sign Out',
    vip: 'Price'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo'
  },
  footer: {
    pp: 'Privacy Policy',
    ts: 'Terms of Service',
    company: 'Company Name',
    email: 'Email',
    address: 'Address',
  }
}
