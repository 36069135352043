<template>
    <div>
        <a-menu mode="horizontal" style="height:64px; line-height: 64px;" @click="menuClick" :selectedKeys="current">
            <a-menu-item key="homelogo" id="logoPic" style="float: left; text-align: left; padding-left: 0px;">
                <div class="logoDiv">
                    <img id="logoImg" src="../../assets/img/FlashX_logo.png"/>
                </div>
            </a-menu-item>
            <a-menu-item key="home">
                <a-icon type="home"/>
                {{$t('header.home')}}
            </a-menu-item>
            <a-menu-item key="download">
                <a-icon type="cloud-download"/>
                {{$t('header.download')}}
            </a-menu-item>
            <a-menu-item key="register">
                <a-icon type="account-book" />
                {{$t('header.vip')}}
            </a-menu-item>
            <a-menu-item key="faq">
                <a-icon type="customer-service"/>
                {{$t('header.helpCenter')}}
            </a-menu-item>


            <a-menu-item key="login" v-if="!listenUserState">
                <a-icon type="user"/>
                {{$t('header.signIn')}}
            </a-menu-item>

            <a-sub-menu v-else>
                <span slot="title" class="submenu-title-wrapper">
                    <a-icon type="menu-fold"/>
                    {{$t('header.myAccount')}}
                </span>
                <a-menu-item key="myAccount">
                    {{$t('header.myAccount')}}
                </a-menu-item>

                <a-menu-item key="signout">
                    {{$t('header.signOut')}}
                </a-menu-item>
            </a-sub-menu>

            <a-sub-menu id="subLang">
                <span slot="title" class="submenu-title-wrapper">
                    <a-icon type="global" />
                    {{ text }}</span>
                <a-menu-item key="setting:1" @click="changeLang('en', 'English')">
                    English
                </a-menu-item>
                <a-menu-item key="setting:2" @click="changeLang('zh', '中文')">
                    中文
                </a-menu-item>
            </a-sub-menu>
        </a-menu>

    </div>
</template>
<script>
    import {getToken, setToken, removeToken} from '@/utils/auth'
    import Cookies from "js-cookie";

    export default {
        name: 'Header',
        data() {
            return {
                current: [],
                menuKeys: "home,download,faq,myAccount,signOut",
                text: 'English',
                account: 'My Account',
                logoSrc: '../../assets/img/orca_logo.png',
                userStatus: false,
                browserLanguage: ''
            };
        },
        computed: {
            haveTk: {
                get() {
                    return (getToken() !== undefined) && (getToken() !== '')
                },
                set() {

                }
            },
            listenUserState: {
                get() {
                    return this.$store.getters.token
                },
                set() {

                }

            }
        },
        watch: {
            '$route':'menuActive',
        },
        created() {
            this.langListen()
        },
        methods: {
            langListen() {
                let path = location.pathname;
                let search = location.search;
                if (path.indexOf('ad') != -1 && path.indexOf('download') == -1) {
                    return;
                }
                //eula不参与
                if (path.indexOf('eula') != -1) {
                    return;
                }
                if (path.indexOf('robots') != -1) {
                    return;
                }
                if (path.indexOf('sitemap') != -1) {
                    return;
                }
                if (path.indexOf('blog') != -1) {
                    return;
                }
                //跳转参数带上指定语言 优先级最高
                if (search.indexOf('lang') !== -1) {
                    if (search.indexOf('zh-CN') !== -1) {
                        this.changeLang('zh', '中文')
                    } else {
                        this.changeLang('en', 'English')
                    }
                    return;
                }
                //未手动设置过语言 初次进入，根据浏览器语言决定
                let cookLang = Cookies.get('language');
                if (!cookLang) {
                    if ('zh-CN' === navigator.language) {
                        this.changeLang('zh', '中文')
                    } else {
                        this.changeLang('en', 'English')
                    }
                    return;
                } else {
                    //设置过cookies
                    if ('zh' == cookLang) {
                        this.changeLang('zh', '中文')
                    } else {
                        this.changeLang('en', 'English')
                    }
                    return;
                }
                //其他情况 根据路由地址判断作出相应语言操作
                if (path.indexOf('cn') !== -1) {
                    this.changeLang('zh', '中文')
                } else {
                    this.changeLang('en', 'English')
                }
            },
            menuActive() {
                let path = location.pathname
                let pathKey = path.replaceAll("/",'').replace('cn','')
                if (pathKey && pathKey.indexOf(this.menuKeys !== -1)) {
                    this.current = [pathKey]
                } else {
                    this.current = []
                }
            },
            online() {
                if (getToken() === undefined || getToken() === '') {
                    return false
                } else {
                    return true
                }
            },
            menuClick(item) {
                let path = item.key
                if (path.indexOf('setting') !== -1) {
                    return
                }
                if (path == 'homelogo') {
                    path = 'home'
                }
                if (path === 'signout') {
                    this.$store.dispatch("LogOut").then(() => {
                        if (this.$i18n.locale === 'zh') {
                            this.$router.push('/cn/login')
                        } else {
                            this.$router.push('/login')
                        }
                        this.current = ['login']
                    }).catch(() => {
                    });
                    return
                }
                //购买 登陆和非登陆路由
                if (path == 'register') {
                    //登陆状态
                    if (this.listenUserState) {
                        if (this.$i18n.locale === 'zh') {
                            this.$router.push('/cn/myAccount?renew=3')
                        } else {
                            this.$router.push('/myAccount?renew=3')
                        }
                        this.current = ['myAccount']
                    } else {
                        //非登陆状态
                        if (this.$i18n.locale === 'zh') {
                            this.$router.push('/cn/register')
                        } else {
                            this.$router.push('/register')
                        }
                        this.current = ['register']
                    }
                    return;
                }
                //路由跳转事件
                if (this.$i18n.locale === 'zh') {
                    this.$router.push('/cn/' + path)
                } else {
                    this.$router.push('/' + path)
                }
                this.current = [path]
            },
            changeLang(lang, text) {
                Cookies.set('language',lang)
                this.$i18n.locale = lang
                this.text = text
                let path = location.pathname
                let search = location.search
                //要保留当前path的参数
                if (lang === 'zh') {
                    if (path.indexOf('cn') === -1) {
                        this.$router.push('/cn' + path + search)
                    }
                }
                if (lang === 'en') {
                    //当前带中文路径
                    if (path.indexOf('cn') !== -1) {
                        this.$router.push(path.replace('/cn','') + search)
                    }
                }
            },
        }
    };
</script>
<style scoped>
    .ant-menu {
        font-size: 15px !important;
        font-weight: bold !important;
    }

    #logoPic {
        border-bottom: 0px !important;
    }

    #logoPic:hover {
        color: transparent !important;
        border-bottom: 0px !important;
    }

    .ant-menu-item-active {
        color: #FF524D !important;
        border-bottom: 2px solid #FF524D !important;
    }

    .ant-menu-item-selected {
        color: #FF524D !important;
        border-bottom: 2px solid #FF524D !important;
    }

    .ant-menu-submenu-selected {
        color: #FF524D !important;
        border-bottom: 2px solid #FF524D !important;
    }

    .ant-menu-submenu-active {
        color: #FF524D !important;
        border-bottom: 2px solid #FF524D !important;
    }

    ::v-deep .ant-menu-submenu-title:hover {
        color: #FF524D !important;
    }

    .logoDiv {
        width: 175px;
        height: 64px;
        float: left;
        text-align: left;
    }

    #logoImg {
        width: 100%;
        margin-left: 10px;
    }

</style>
