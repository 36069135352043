import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/home/index.vue')
  },
  {
    path: '/cn',
    name: 'cn_index',
    component: () => import(/* webpackChunkName: "cn_index" */ '../views/home/cn_index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "orcaHome" */ '../views/home/index.vue')
  },
  {
    path: '/cn/home',
    name: 'cn_home',
    component: () => import(/* webpackChunkName: "cn_orcaHome" */ '../views/home/cn_index.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/helpCenter/index.vue')
  },
  {
    path: '/cn/faq',
    name: 'cn_faq',
    component: () => import(/* webpackChunkName: "cn_faq" */ '../views/helpCenter/cn_index.vue')
  },
  {
    path: '/region-guide-ios',
    name: 'regionGuideIos',
    component: () => import(/* webpackChunkName: "regionGuideIos" */ '../views/regionGuideIos/index.vue')
  },
  {
    path: '/cn/region-guide-ios',
    name: 'cn_regionGuideIos',
    component: () => import(/* webpackChunkName: "cn_regionGuideIos" */ '../views/regionGuideIos/cn_index.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "download" */ '../views/download/index.vue')
  },
  {
    path: '/cn/download',
    name: 'cn_download',
    component: () => import(/* webpackChunkName: "cn_download" */ '../views/download/cn_index')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register/index_bak3.vue')
  },
  {
    path: '/cn/register',
    name: 'cn_register',
    component: () => import(/* webpackChunkName: "cn_register" */ '../views/register/cn_index_bak3.vue')
  },
  {
    path: '/myAccount',
    name: 'MyAccount',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/myAccount/index_bak_2.vue')
  },
  {
    path: '/cn/myAccount',
    name: 'cn_MyAccount',
    component: () => import(/* webpackChunkName: "cn_myAccount" */ '../views/myAccount/cn_index_bak4.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "orcaLogin" */ '../views/login/index.vue')
  },
  {
    path: '/cn/login',
    name: 'cn_login',
    component: () => import(/* webpackChunkName: "cn_orcaLogin" */ '../views/login/cn_index')
  },
  {
    path: '/bindEmail',
    name: 'BindEmail',
    component: () => import(/* webpackChunkName: "bindEmail" */ '../views/bindEmail/index.vue')
  },
  {
    path: '/cn/bindEmail',
    name: 'cn_BindEmail',
    component: () => import(/* webpackChunkName: "cn_bindEmail" */ '../views/bindEmail/cn_index.vue')
  },
  {
    path: '/forgetPwd',
    name: 'ForgetPwd',
    component: () => import(/* webpackChunkName: "forgetPwd" */ '../views/forgetPwd/index.vue')
  },
  {
    path: '/cn/forgetPwd',
    name: 'cn_ForgetPwd',
    component: () => import(/* webpackChunkName: "cn_forgetPwd" */ '../views/forgetPwd/cn_index.vue')
  },
  {
    path: '/resetPwd',
    name: 'ResetPwd',
    component: () => import(/* webpackChunkName: "resetPwd" */ '../views/resetPwd/index.vue')
  },
  {
    path: '/cn/resetPwd',
    name: 'cn_ResetPwd',
    component: () => import(/* webpackChunkName: "cn_resetPwd" */ '../views/resetPwd/cn_index.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/privacyPolicy/index.vue')
  },
  {
    path: '/cn/privacyPolicy',
    name: 'cn_PrivacyPolicy',
    component: () => import(/* webpackChunkName: "cn_privacyPolicy" */ '../views/privacyPolicy/cn_index.vue')
  },
  {
    path: '/terms-of-service',
    name: 'TermsService',
    component: () => import(/* webpackChunkName: "termsService" */ '../views/termsService/index.vue')
  },
  {
    path: '/cn/terms-of-service',
    name: 'cn_TermsService',
    component: () => import(/* webpackChunkName: "cn_termsService" */ '../views/termsService/cn_index.vue')
  },
  {
    path: '/pay-success',
    name: 'paySuccess',
    component: () => import(/* webpackChunkName: "paySuccess" */ '../views/paySuccess/index.vue')
  },
  {
    path: '/cn/pay-success',
    name: 'cn_paySuccess',
    component: () => import(/* webpackChunkName: "cn_paySuccess" */ '../views/paySuccess/cn_index.vue')
  },
  {
    path: '/pay-cancel',
    name: 'payCancel',
    component: () => import(/* webpackChunkName: "payCancel" */ '../views/payCancel/index.vue')
  },
  {
    path: '/cn/pay-cancel',
    name: 'cn_payCancel',
    component: () => import(/* webpackChunkName: "cn_payCancel" */ '../views/payCancel/cn_index.vue')
  },
  {
    path: '/ad',
    name: 'ad',
    component: () => import(/* webpackChunkName: "ad" */ '../views/ad/index.vue')
  },
  {
    path: '/cn/ad',
    name: 'cn_ad',
    component: () => import(/* webpackChunkName: "cn_ad" */ '../views/ad/cn_index.vue')
  },
  {
    path: '/cn/ad2',
    name: 'cn_ad2',
    component: () => import(/* webpackChunkName: "cn_ad2" */ '../views/ad/cn_index2.vue')
  },
  {
    path: '/cn/ad3',
    name: 'cn_ad3',
    component: () => import(/* webpackChunkName: "cn_ad3" */ '../views/ad/cn_index3.vue')
  },
  {
    path: '/cn/adh',
    name: 'cn_adh',
    component: () => import(/* webpackChunkName: "cn_adh" */ '../views/ad/cn_indexh.vue')
  },
  {
    path: '/cn/adh2',
    name: 'cn_adh2',
    component: () => import(/* webpackChunkName: "cn_adh2" */ '../views/ad/cn_indexh2.vue')
  },
  {
    path: '/cn/ad9',
    name: 'cn_ad9',
    component: () => import(/* webpackChunkName: "cn_adh" */ '../views/ad/cn_index9.vue')
  },
  {
    path: '/cn/ad8',
    name: 'cn_ad8',
    component: () => import(/* webpackChunkName: "cn_adh" */ '../views/ad/cn_index8.vue')
  },
  {
    path: '/pay-result',
    name: 'payResult',
    component: () => import(/* webpackChunkName: "payResult" */ '../views/sge/index.vue')
  },
  {
    path: '/cn/pay-result',
    name: 'cn_payResult',
    component: () => import(/* webpackChunkName: "cn_payResult" */ '../views/sge/cn_index.vue')
  },
  {
    path: '/cn/ios-help',
    name: 'iosHelp',
    component: () => import(/* webpackChunkName: "iosHelp" */ '../views/iosHelp/cn_index.vue')
  },
  {
    path: '/cn/ios-doc1',
    name: 'iosDoc1',
    component: () => import(/* webpackChunkName: "iosDoc1" */ '../views/iosHelp/iosHelp1.vue')
  },
  {
    path: '/cn/ios-doc2',
    name: 'iosDoc2',
    component: () => import(/* webpackChunkName: "iosDoc2" */ '../views/iosHelp/iosHelp2.vue')
  },
  {
    path: '/cn/ios-doc3',
    name: 'iosDoc3',
    component: () => import(/* webpackChunkName: "iosDoc3" */ '../views/iosHelp/iosHelp3.vue')
  },
  {
    path: '/cn/ios-doc4',
    name: 'iosDoc4',
    component: () => import(/* webpackChunkName: "iosDoc4" */ '../views/iosHelp/iosHelp4.vue')
  },
  {
    path: '/eula',
    name: 'eula',
    component: () => import(/* webpackChunkName: "eula" */ '../views/eula/index.vue')
  },
]

// const router = new VueRouter({
//   routes
// })

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})

export default router


