<template>
    <div id="app" class="components-layout-demo-basic">
        <a-layout style="min-height: 100vh;background: white">
            <a-layout-header id="flashHeader"
                             :style="{ zIndex: 1, width: '100%', padding: '0px', textAlign: 'right', background: 'white', position: 'fixed'}">
                <Header/>
            </a-layout-header>
            <a-layout-content id="flashMain" :style="{flex: 1,background: '#F7FAFD', marginTop: '64px'}">
                <router-view/>
            </a-layout-content>
            <a-layout-footer id="flashFooter" :style="{height:'124px', background: 'white', width: '100%', color: 'black'}">
                <a-row>
                    <a-col :xs="0" :sm="0" :md="24" :lg="24" :xl="24" :xxl="24">
                        <span style="cursor: pointer; text-decoration: underline" @click="goPP">{{$t('footer.pp')}}</span>
                        <span style="margin: 0 10px">|</span>
                        <span style="cursor: pointer; text-decoration: underline" @click="goTs">{{$t('footer.ts')}}</span>
<!--                        <span style="margin: 0 10px">|</span>-->
<!--                        <span>{{$t('footer.company')}}：TOPSTAND TECHNOLOGY PTE. LTD.</span>-->
                        <span style="margin: 0 10px">|</span>
                        <span>{{$t('footer.email')}}：support@flashxvpn.com</span>
<!--                        <span style="margin: 0 10px">|</span>-->
<!--                        <span>{{$t('footer.address')}}： 133 NEW BRIDGE ROAD #10-03 CHINATOWN POINT SINGAPORE (059413)</span>-->
                        <a-divider></a-divider>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="0" :lg="0" :xl="0" :xxl="0">
                        <span style="cursor: pointer; text-decoration: underline" @click="goPP">{{$t('footer.pp')}}</span>
                        <span style="margin: 0 10px">|</span>
                        <span style="cursor: pointer; text-decoration: underline" @click="goTs">{{$t('footer.ts')}}</span>
                        <a-divider></a-divider>
                    </a-col>
                </a-row>
                © 2023 FlashX VPN. All rights reserved.
            </a-layout-footer>
            <img height="1" width="1" style="display:none"
                 src="https://www.facebook.com/tr?id=300845352722900&ev=PageView&noscript=1"/>
        </a-layout>
    </div>
</template>

<script>

    import Header from '@/components/header/Header.vue'
    import DisableDevtool from 'disable-devtool';

    export default {
        components: {
            Header
        },
        computed: {},
        data() {
            return {}
        },
        created() {
            DisableDevtool({md5: '75ac2c91d93efba8651671f18ec013d0', url: 'https://www.baidu.com/'});
        },
        methods: {
            goPP() {
                if ('zh' === this.$i18n.locale) {
                    this.$router.push('/cn/privacyPolicy')
                } else {
                    this.$router.push('/privacyPolicy')
                }
            },
            goTs() {
                if ('zh' === this.$i18n.locale) {
                    this.$router.push('/cn/terms-of-service')
                } else {
                    this.$router.push('/terms-of-service')
                }
            }
        },
    }
</script>

<style lang="less">
    #app {
        /*font-family: '阿里巴巴普惠体', '仓耳渔阳体 W05', Avenir, Helvetica, Arial, sans-serif;*/
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: var(--default-text-color);
        overflow: auto;
        //height: 100%;
    }
    .components-layout-demo-basic {
        text-align: center;
    }
    .components-layout-demo-basic .ant-layout-header,
    .components-layout-demo-basic .ant-layout-footer {
        background: #7dbcea;
        color: #fff;
    }
</style>
